body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.circle {
  border-radius: 50%;
}

.twitch-link {
  color:#9146ff;
}

.youtube-link {
  color:red;
}

.facebook-link {
  color:#1877F2;
}

table.table th, table.table td {
  vertical-align: middle;
}